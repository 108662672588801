import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import BlockContent from '../components/block-content'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
query AboutPageQuery {
    person: sanityPerson {
      id
      name
      slug {
        current
      }
      _rawBio
      clients
      services
    }
  }
  
  
`

const AboutPage = props => {
  const {data, errors} = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const person = data.person

  return (
    <Layout>
      <SEO title='Eric Hu studio: Profile' />
      <Container>
        <div className='mt-24 px-4 mb-4 mx-auto lg:max-w-4xl'>
          <div className='text-xl lg:text-2xl lg:tracking-tight lg:mx-auto'>{person._rawBio && <BlockContent blocks={person._rawBio || []} />}</div>
          <div>
            <ul className='p-4'>
              <li>Instagram: <a className='underline' href='http://instagram.com/_erichu' target='_blank'>@_erichu</a></li>
              <li>Inquiries: <a className='underline' href='mailto:studio@erichu.info'>studio@erichu.info</a></li>
              <li>( Commercial PDF Available on Request )</li>
            </ul>
          </div>
          <div className='pt-4'>
            Services
            <ul>
              {person.services.map((service, i) => (
                <li className='pl-4' key={i}>{service}</li>
              ))}
            </ul>
          </div>
          <div className='pt-4 inline-block'>
            Clients
            <ul style={{columnCount: '2'}} className='col-count-2'>
              {person.clients.map((client, i) => (
                <li className='pl-4' key={i}>{client}</li>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default AboutPage
